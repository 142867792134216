<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" v-if="objectCodeId > 0">Object Code Edit</th>
              <th scope="col" v-else>Object Code Add</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-form>
        <b-row>
          <b-col cols="12" sm="6" md="6" xl="6">
            <b-form-group label="Code" label-for="code">
              <b-form-input required id="code" type="text" v-model="objectCodeValue.code"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="6" xl="6">
            <b-form-group label="Description" label-for="description">
              <b-form-input required id="description" type="text" v-model="objectCodeValue.description"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col cols="12">
            <b-button v-if="objectCodeId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save
            </b-button>
            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import router from '@/router';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const OBJECT_CODE_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(OBJECT_CODE_APP_STORE_MODULE_NAME)) store.registerModule(OBJECT_CODE_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBJECT_CODE_APP_STORE_MODULE_NAME)) store.unregisterModule(OBJECT_CODE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      objectCodeValue: {
        code: '',
        description: '',
      },

      loading: false,
      objectCodeId: null,
    };
  },

  watch: {},

  mounted() {
    this.getObjectCodeById();
  },

  methods: {
    getObjectCodeById() {
      this.loading = true;

      if (router.currentRoute.params.id) {
        this.objectCodeId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('settings/getObjectCodeById', { id: router.currentRoute.params.id })
          .then((res) => {
            this.objectCodeValue = res.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.loading = false;
      }
    },

    formSubmitted() {
      this.loading = true;
      if (!this.objectCodeValue.code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please fill in all fields.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.loading = false;
        return;
      }

      if (this.objectCodeId == null) {
        store
          .dispatch('settings/saveObjectCode', this.objectCodeValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Object Code Add Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-objectcodes' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        store
          .dispatch('settings/updateObjectCode', this.objectCodeValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Object Code Update Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-objectcodes' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
